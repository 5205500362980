.no-wrap {
  white-space: nowrap;
}

.ligne-clickable:hover {
  cursor: pointer;
  background: aliceblue;
}

.cell-clickable:hover {
  cursor: pointer;
  background: aliceblue;
}

.hidden-input {
  border: 0;
}

/* Chrome, Safari, Edge, Opera */
.hidden-input::-webkit-outer-spin-button,
.hidden-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hidden-input {
  -moz-appearance: textfield;
}

.hidden-input:focus {
  background-color: rgba(62,125,213,0.1);
}

.hidden-input::selection {
  background: rgba(62,125,213,0.30);
}

.hidden-input:focus + .excel-cursor-outer {
  display: block;
}
.excel-cursor-outer {
  display: none;
  position: relative;
}

.excel-cursor {
  cursor: ns-resize;
  position: absolute;
  top: -1px;
  right: -4px;
  background: rgba(62,125,213,1);
  width: 7px;
  height: 7px;
  border: 1px solid #fff;
  border-right: 0px;
  border-bottom: 0px;
}

.hover-select {
  background-color: rgba(62,125,213,0.1);
}

.full-viewport {
  user-select: none;
  background: white;
  position: absolute;
  //height: 100vh;
  width: 99vw;
  top: -176px;
  left: -105px;
  //right:0px;
  //bottom:0px;
  z-index: 9999;
}

.th-action-sticky {
  position: sticky;
  top: 0;
}

.th-title-sticky {
  position: sticky;
  top: 62px;
}

