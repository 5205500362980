.map {
  overflow: hidden;
}

.map-image {
  width: 50%;
  float: left;
}

.map-image path{
  fill: #6691e7;
  stroke: white;
  stroke-width: 1px;
  transition: fill 0.3s;
}

.map-image .active path{
  fill: darkgrey!important;
}

.map-image .selected path{
  fill: dimgrey!important;
}

.map-list {
  width: 50%;
  float: right;
}

.map-list a{
  color: inherit;
  text-decoration: none;
  transition: color 0.3s;
}

.map-list a.active{
  fill: rgba(255, 0, 0, 0.76);
}